
import { ContentLoader } from "vue-content-loader";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: { ContentLoader }
})
export class TableCardLoader extends Vue {
  @Prop({ default: () => 800 })
  width!: number;

  @Prop({ default: () => 110 })
  height!: number;

  @Prop({ default: () => 2 })
  speed!: number;

  @Prop({ default: () => 3 })
  rowCount!: number;

  @Prop({ default: () => 5 })
  colCount!: number;

  @Prop({ default: () => 150 })
  cellWidth!: number;

  calcX(i: number): number {
    const w = this.cellWidth;
    // const s = (16/w) * 100;
    const s = 8;

    i = i - 1;

    return w * i + s * i;
  }

  calcY(i: number): number {
    const t = 32 + 8;

    if (i === 1) {
      return t;
    }

    return t + 16 * (i - 1) + 8 * (i - 1);
  }
}
export default TableCardLoader;
