
import { Component, Prop, PropSync } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { MR, MRFilterQueryPayload } from "@/store/modules";
import { CDataTableFields } from "@/@types";
// import {AnyObject} from '@/utility';
// import { RootState } from '@/store/types';
import MRRequestStatusBadge from "./detail/MRRequestStatusBadge.vue";

@Component({ components: { MRRequestStatusBadge } })
export default class MRList extends mixins(
  DateFormatMixin,
  HeMixin,
  CurrencyFormatMixin
) {
  @Prop({ default: () => [] })
  private items!: MR[];

  @Prop({ default: () => false })
  private loading!: boolean;

  @Prop({ default: () => true })
  private itemsPerPageSelect!: boolean;

  @Prop({ default: () => false })
  private pagination!: boolean;

  @PropSync("itemsPerPage", { default: () => 25, type: Number })
  itemsPerPageSync!: number;

  @PropSync("columnFilterValue", { default: () => ({}), type: Object })
  columnFilterValueSync!: MRFilterQueryPayload;

  @PropSync("sorterValue", { default: () => ({}), type: Object })
  sorterValueSync!: { column: string | null; asc: string | boolean };

  @Prop({ default: () => [] })
  private excludeColumns!: Array<string>;

  @Prop(Boolean)
  private disableAllFilters?: boolean;

  @Prop(Boolean)
  private disableAllSort?: boolean;

  // items: Array<any> = [];
  fields: CDataTableFields = [
    { key: "status", label: "Status", filter: false, sorter: false },
    { key: "id", label: "#", filter: true, _style: "width:80px" },
    { key: "taskNumber", label: "Task", filter: true },
    { key: "customer_name", label: "Customer", filter: true },
    {
      key: "address_data",
      label: "Address",
      filter: true,
      _style: "width:300px",
    },
    { key: "tech_name", label: "Tech", filter: false },
    {
      key: "created_at",
      label: "Created",
      filter: false,
      _style: "width:400px",
      // _style: "width:100px",
    },
    { key: "cost", label: "Rate", filter: false },
    { key: "totalTime", label: "Time", filter: false },
    // { key: "completed", label: "Complete", filter: false },
    { key: "paid", label: "Paid", filter: false },
    { key: "partsPending", label: "Parts", filter: false },
    // { key: "approved", label: "Approved", filter: false },
    // { key: "voided", label: "Void", filter: false },
    { key: "signed", label: "Signed", filter: false },
    // { key: 'taskDescription', label: 'Desc' },
  ];

  limit = 25;
  // loading: boolean = true;

  /*mounted() {
    this.$nextTick(() => this.fetchRender());
  }

  fetchRender() {
    MR.dispatch("fetchData");
  }*/

  onColumnFilterChange(val: MRFilterQueryPayload) {
    this.columnFilterValueSync = val;
  }

  onSorterValueChange(val: { column: string; asc: boolean }) {
    this.sorterValueSync = { ...val };
  }

  onTotalPageChange(val: number) {
    this.itemsPerPageSync = val;
  }

  get columns(): CDataTableFields {
    const fields = this.fields.map((f) => {
      const field = { ...f };
      if (true === this.disableAllFilters) {
        field.filter = false;
      }

      if (true === this.disableAllSort) {
        field.sorter = false;
      }
      return field;
    });
    if (!this.excludeColumns.length) {
      return fields;
    }

    return fields.filter(
      (field) => this.excludeColumns.indexOf(field.key) === -1
    );
  }

  setStartDateFilter(e: Event) {
    if (!e.target) {
      return;
    }
    const s = { ...this.columnFilterValueSync };
    s.startDate = (e.target as HTMLInputElement).value;
    this.columnFilterValueSync = s;
  }

  setEndDateFilter(e: Event) {
    if (!e.target) {
      return;
    }

    const s = { ...this.columnFilterValueSync };
    s.endDate = (e.target as HTMLInputElement).value;
    this.columnFilterValueSync = s;
  }

  /*get items(): Array<any> {
    const data = MR.query().withAll().get();
    console.log('data', data);
    return data;
  }*/

  /*get loading(): boolean {
    return !!MR.store().state.entities.mr.fetching;
  }*/

  /*get pagination(): boolean | AnyObject {
    const total = MR.store().state.entities.mr.total;
    if(total) {
      console.log('test', Math.min(Math.ceil(total / this.limit), 1) );
      return {
        pages: Math.min(Math.ceil(total / this.limit), 1),
        activePage: 2
      };
    }
    return false;
  }*/
}
