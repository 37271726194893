
import { MR, MRInterface } from "@/store/modules";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
class MRRequestStatusBadge extends Vue {
  @Prop(MR)
  private record!: MRInterface;

  text() {
    switch (true) {
      case this.record.voided:
        return "Void";
      case this.record.completed:
        return "Complete";
      case this.record.approved:
        return "Approve";
      case this.record.on_hold:
        return "On Hold";
    }

    return "Open";
  }

  color() {
    switch (true) {
      case this.record.voided:
        return "danger";
      case this.record.completed:
        return "success";
      case this.record.approved:
        return "info";
      case this.record.on_hold:
        return "light";
    }

    return "warning";
  }
}

export default MRRequestStatusBadge;
